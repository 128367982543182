import React from 'react';
import { createRoot } from 'react-dom/client';
import Questionnaire from './questionnaire';
import Identification from './identification';
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams
} from "react-router-dom";


function Body(props) {
  let { code } = useParams();

  return (
    <Questionnaire code={code} signOnly={props.signOnly} />
  );
}

function RedirectCode(c) {
  window.top.location.href='/'+c;
}

const container = document.getElementById('root');

createRoot(container).render(
  <React.StrictMode>
    <Router>
      <Routes>
          <Route path="/" exact element={<Identification onLogin={RedirectCode} mode="guest" /> } />
          <Route path="/clinique" exact element={<Identification onLogin={RedirectCode} mode="code" />} />
          <Route path="/continuer" exact element={<Identification onLogin={RedirectCode} mode="registered" /> } />
          <Route path="/qrsign/:code" element={<Body signOnly={true} />} />
          <Route path="/:code" element={<Body signOnly={false} />} />
      </Routes>
    </Router>
  </React.StrictMode>);

